import { createActions, createReducer } from "reduxsauce";
const { Types, Creators } = createActions({
  requestAction: [],
  errorAction: ["error"],
  successfulGetMetaTagsService: ["payload"],
});

export const MetaTagsActionCreators = Creators;

const initialState = {
  loading: false,
  error: "",
  metatags: {
    title: "",
    description: "",
    keywords: "",
  },
};

const requestAction = (state = initialState) => ({
  ...state,
  loading: true,
  error: "",
});

const successfulGetMetaTagsService = (state = initialState, action) => {
  const { metatags } = action.payload.result;

  return {
    ...state,
    loading: false,
    metatags: metatags,
  };
};

const errorAction = (state = initialState, action) => ({
  ...state,
  loading: false,
  error: action.error,
});

export default createReducer(initialState, {
  [Types.REQUEST_ACTION]: requestAction,
  [Types.ERROR_ACTION]: errorAction,
  [Types.SUCCESSFUL_GET_META_TAGS_SERVICE]: successfulGetMetaTagsService,
});
